import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../Navbar/NavLinks";
import logo from "../../images/logo/logo.png";

const NavBar = () => {
  const [top, setTop] = useState(true);
  const [scrollPositionY, setScrollPositionY] = useState(0);

  useEffect(() => {
    const scrollHandler = () => {
      setScrollPositionY((p) => {
        if (window.pageYOffset <= p) {
          setTop(true);
        } else {
          setTop(false);
        }
        return window.pageYOffset;
      });
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top, setScrollPositionY]);

  return (
    <nav
      className={`z-30 hidden md:block fixed w-full transition bg-white duration-1000 ease-in-out border-sky-700 translate-y-[-20px] border-b  shadow-md bg-white-100 pt-4 h-24`}
    >
      <div className="flex flex-row justify-between ">
        <div className="flex flex-row  md:px-4 lg:mx-12 lg:mx-6 items-center text-center font-semibold">
          <Link
            to="/"
            scroll={(el) => {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
              setTop(true);
            }}
          >
            <div className="pb-4 content-center">
              <img src={logo} className="h-8" />
            </div>
          </Link>
        </div>
        <div className="group flex flex-col">
          <div className="space-x-6 lg:inline-block p-5">
            <NavLinks />
          </div>
          <div
            className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white rounded-lg block lg:hidden shadow-xl top-14 ${"block"} `}
          >
            <div className="flex flex-col">
              <NavLinks />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
