import React from "react";
import img from "../images/Web-developer.svg";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <div className="about" id="about">
      <div className="m-auto max-w-6xl pl-2 pr-2 pb-2 md:pr-12 md:pl-12 md:pt-12 h-5/6 align-items:center">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 lg:text-left"
          data-aos="fade-up"
        >
          {/* <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
            <img alt="card img" className="rounded-t float-right" src={img} />
          </div> */}
          <div
            className="flex-col my-4 lg:text-left lg:my-0 lg:justify-end w-full  px-8"
            data-aos="zoom-in"
            data-aos-delay="0"
          >
            <div className="my-4 py-4">
              <h2 className="my-2 text-center text-3xl text-sky-900 uppercase font-bold">
                about
              </h2>
            </div>
            <div>
              <p className="my-3 text-l text-slate-700 font-semibold">
                With 15 years of experience in the industry, our team of 2
                engaged consultants will make technology work for you.
              </p>
            </div>
            <div>
              <p className="my-3 text-l text-slate-700 font-semibold">
                Bringing together craftsmanship and stakeholder proposals, the
                initiatives in your domain will see increased value.
              </p>
            </div>
            <Link
              to="/contact"
              className="text-white bg-sky-700 hover:bg-slate-700 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
            >
              Contact us
              <svg
                className="w-4 h-4 ml-1 group-hover: translate-x-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
