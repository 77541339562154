import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-slate-700 hover:text-sky-700"
        to="/#about"
        scroll={(el) => {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }}
      >
        About
      </HashLink>
      <HashLink
        scroll={(el) => {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }}
        className="px-4 font-extrabold text-slate-700 hover:text-sky-700"
        to="/#services"
      >
        Services
      </HashLink>
      <HashLink
        scroll={(el) => {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }}
        className="px-4 font-extrabold text-slate-700 hover:text-sky-700"
        to="/#clients"
      >
        Clients
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-slate-700 hover:text-sky-700"
        to="/contact"
      >
        Contact us
      </HashLink>
    </>
  );
};

export default NavLinks;
