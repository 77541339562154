import React from "react";
import img from "../images/web.svg";
import img2 from "../images/mobile.svg";
import img3 from "../images/datascience.svg";
import img4 from "../images/business.svg";

const Services = () => {
  return (
    <div id="services" className="lg:py-24">
      <section data-aos="zoom-in-down">
        <div className="my-8 py-4">
          <h2 className="my-2 text-center text-3xl text-sky-900 uppercase font-bold">
            services
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">
            <div
              id="1"
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-700 hover:bg-white rounded-lg shadow-2xl p-3 group"
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t  group-hover:scale-[1.15] transition duration-1000 ease-in-out w-full h-60 "
                  src={img}
                />
                <h2 className="font-semibold my-4 text-xl text-center w-full">
                  Web Applications
                </h2>
                <p className="text-md font-medium">
                  Solve current and future business needs in your domain and
                  join in creating value; out of your ideas. We ensure your application is up to date with industry security standards and leverage Agile methodologies as well as validated technologies during development. 
                </p>
              </div>
            </div>

            <div
              id="2"
              className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-700 hover:bg-white rounded-lg shadow-2xl p-3 group"
            >
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t scale-75 group-hover:scale-[1] transition duration-1000 ease-in-out w-full h-60"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Mobile App Development
                </h2>
                <p className="text-md font-medium">
                  We develop high-quality, custom cross-platform mobile
                  applications that are robust and optimized for performance,
                  scalability, and accessibility.
                </p>
              </div>
            </div>

            {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-700 rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t scale-80 group-hover:scale-[1] transition duration-1000 ease-in-out w-full h-60"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-xl text-center ">
                  Data Science
                </h2>
                <p className="text-md font-medium">
                  Tailor your data management and visualisation tools to help
                  forecast your business and stay ahead of the market.
                </p>
              </div>
            </div> */}

            {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-700 rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t scale-80 group-hover:scale-[1] transition duration-1000 ease-in-out w-full h-60"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-xl text-center ">
                  Business Analytics
                </h2>
                <p className="text-md font-medium">
                  Build tooling for data driven insights to identify new revenue
                  streams, or where you can optimize cost.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
