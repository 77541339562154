import React from "react";
import { HashLink } from "react-router-hash-link";

const Hero = () => {
  return (
    <>
      <div className="m-auto hero overflow-hidden mt-6 lg:mt-4  md:pl-12 h-6/6 pt-64 pb-64 px-12">
        <div
          className="flex flex-col lg:flex-row justify-between text-center lg:text-left"
          data-aos="zoom-in"
          data-aos-delay="0"
        >
          <div
            className="sm: pl-2 sm: pr-2 sm:pt-2 lg:w-1/2 flex flex-col justify-center"
            data-aos="zoom-in"
            data-aos-delay="0"
          >
            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-white">
              IT Consultancy
            </h1>
            <div className="text-xl font-semibold tracking-tight mb-5 text-white">
              On-demand software development and consulting services for your business.
            </div>
            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
              <HashLink
                to="/#services"
                scroll={(el) => {
                  const isMobile = window.innerWidth <= 768; // Example threshold for mobile width
                  const scrollOptions = {
                    behavior: "smooth",
                    block: isMobile ? "start" : "end", // Scroll to top on mobile, end on desktop
                  };
                  el.scrollIntoView(scrollOptions);
                }}
                className="text-white hover:text-sky-700 bg-sky-700 hover:bg-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
              >
                See services
                <svg
                  className="w-4 h-4 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
